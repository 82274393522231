import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import './FormCard.css';

type Props = {
  title: string;
  children: React.ReactNode;
  icon: React.ReactElement;
  headerButton?: React.ReactElement;
  collapsable?: boolean;
  defaultCollapsed?: boolean;
  position?: 'fixed' | 'absolute' | 'relative';
  onContentChange?: () => void;
};
type FormCardChildProps = {
  onContentChange?: () => void;
};

const FormCard = ({
  title,
  children,
  icon,
  headerButton,
  collapsable = false,
  defaultCollapsed = false,
  position = 'relative',
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const updateHeight = () => {
    if (contentRef.current) {
      contentRef.current.style.height = 'auto';
      const newHeight = isCollapsed
        ? '0px'
        : `${contentRef.current.scrollHeight}px`;
      contentRef.current.style.height = newHeight;
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(updateHeight);

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    updateHeight();

    return () => {
      if (contentRef.current) {
        resizeObserver.unobserve(contentRef.current);
      }
    };
  }, [isCollapsed, collapsable, children]);

  useEffect(() => {
    // Trigger height recalculation when children change
    updateHeight();
  }, [children, isCollapsed]);

  return (
    <div
      className="bg-white w-full p-4 flex flex-col rounded-lg shadow-md mb-4"
      style={{ position }}
    >
      <div
        onClick={collapsable ? toggleCollapse : () => {}}
        className={`${collapsable ? 'cursor-pointer' : ''}`}
      >
        <div className="flex justify-between">
          <p
            className="flex gap-titleIcon mt-2 text-title items-center"
            style={{ marginBlockEnd: 0 }}
          >
            {icon}
            <span className="flex items-center">{title}</span>
          </p>
          <div className="flex flex-row gap-4">
            {headerButton}
            {collapsable && (
              <Button
                type="text"
                onClick={toggleCollapse}
                icon={isCollapsed ? <PlusOutlined /> : <MinusOutlined />}
              />
            )}
          </div>
        </div>
        <Divider className="mt-dividerTop mb-dividerBottom" />
      </div>
      <div
        ref={contentRef}
        className="collapsible-content"
        style={{ overflow: 'hidden', transition: 'height 0.3s ease' }}
      >
        {/* Pass down the updateHeight function to trigger recalculations */}
        <div className="gap-4 flex flex-col">
          {React.Children.map(children, (child) => {
            return React.isValidElement<FormCardChildProps>(child)
              ? React.cloneElement(child, { onContentChange: updateHeight })
              : child;
          })}
        </div>
      </div>
    </div>
  );
};

export default FormCard;
