import { Button, Modal, Spin, Typography } from 'antd';
import { Formik, FormikProps } from 'formik';
import { AxiosError } from 'axios';
import { useNotifications } from '../../permissions/components/timesheet/Notification-service';
import { useState, useRef } from 'react';
import { LockOutlined } from '@ant-design/icons';
import FormCard from '../../utils/Forms/FormCard';
import ChangePasswordForm from './ChangePasswordForm';
import { AppColors } from '../../../shared/colors';
import { yupValidationPassword } from './passwordValidation';
import { changePwd } from '../../api-requests/Auth';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { MdCheckCircle } from 'react-icons/md';

const { Text } = Typography;

export type TChangePwd = {
  old_password: string;
  new_password: string;
  confirm_password: string;
};

const UserProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef<FormikProps<TChangePwd> | null | undefined>();
  const { openNotification } = useNotifications();
  const navigate = useNavigate();

  const submitStep = async (values: TChangePwd) => {
    console.log('values change pwd', values);
    try {
      setIsLoading(true);
      await changePwd({
        old_password: values.old_password,
        new_password: values.new_password,
      });
      setShowModal(true);
      formRef.current?.resetForm();
    } catch (err) {
      if (err instanceof AxiosError) {
        openNotification('topRight', {
          title: `Change Password`,
          description: err?.response?.data.error,
          type: 'Danger',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="px-0 py-2 md:px-8 md:py-10 2xl:py-10 2xl:px-44"
      style={{
        width: '100%',
        flex: 'flex',
        justifyContent: 'center',
      }}
    >
      <Modal
        width={600}
        open={showModal}
        onCancel={() => {
          formRef.current?.resetForm();
          setShowModal(false);
          navigate('/');
        }}
        title={'Change Password'}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              formRef.current?.resetForm();
              setShowModal(false);
              navigate('/');
            }}
          >
            <span className="indicator-label">{'Ok'}</span>
          </Button>,
        ]}
      >
        <div className="flex flex-col items-center space-y-4 py-10 px-6">
          <MdCheckCircle className="text-green-500 text-3xl" />
          <Text className="text-gray-800 font-medium">
            Your password has been successfully updated. Please log in again to
            continue.
          </Text>
        </div>
      </Modal>
      <Formik
        innerRef={(formikProps) => {
          if (formikProps) {
            formRef.current = formikProps;
          }
        }}
        validationSchema={yupValidationPassword}
        initialValues={{
          old_password: '',
          new_password: '',
          confirm_password: '',
        }}
        enableReinitialize
        onSubmit={async (values) => {
          await submitStep(values);
        }}
        validateOnBlur={true}
      >
        {({ values, setFieldValue, handleSubmit, resetForm }) => {
          return (
            <form onSubmit={handleSubmit} id="changePwdForm">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
                className="mt-4 h-full gap-4"
              >
                <FormCard
                  title="Change Password"
                  icon={<LockOutlined />}
                  collapsable={false}
                >
                  <ChangePasswordForm
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </FormCard>
              </div>
              <div className="flex items-center justify-end mt-2">
                {isLoading ? (
                  <Loader spin={true} />
                ) : (
                  <Button
                    data-testid="changePwd"
                    size={'large'}
                    style={{
                      background: AppColors.primary,
                      color: 'white',
                    }}
                    // onClick={() => {
                    //   console.log('Submit');
                    // }}
                    htmlType="submit"
                    form={'changePwdForm'}
                  >
                    <span className="indicator-label">Change Password</span>
                  </Button>
                )}
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UserProfile;
