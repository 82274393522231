import { Layout, Typography, Button, Spin, Modal } from 'antd';
import { useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../helpers';
import { Formik, FormikProps } from 'formik';
import LoomInput from '../../../../components/StyledComponents/LoomInput';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import { resetPwd } from '../../../../api-requests/Auth';
import PasswordToggle from '../../../../components/ui/PasswordToggle';
import { yupValidationPasswordWithoutOldPwd } from '../../../../pages/userProfile/passwordValidation';
import { TChangePwd } from '../../../../pages/userProfile/UserProfile';
import { MdEmail } from 'react-icons/md';
import CommonAlert from '../../../../components/alert/CommonAlert';

const { Text } = Typography;
type TSetPwd = Omit<TChangePwd, 'old_password'>;

const SetPwd = () => {
  const [searchParams] = useSearchParams();
  const formRef = useRef<FormikProps<TSetPwd>>();
  const [showErrorModal, setShowErrorModal] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState({
    new_password: false,
    confirm_password: false,
  });
  const navigate = useNavigate();
  const rt = searchParams.get('rt') || '';
  const email = searchParams.get('email') || '';

  const handlePasswordToggle = (field: string, visible: boolean) => {
    setPasswordVisibility((prev) => ({ ...prev, [field]: visible }));
  };

  const handleResetPwd = async (values: TSetPwd) => {
    console.log('set new pwd', values, email, rt);
    setIsLoading(true);
    try {
      const response = await resetPwd({
        email,
        rt,
        new_password: values.new_password,
      });
      console.log('success', response);
      setShowSuccessModal('Your password has been changed successfully.');
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log('ERROR', err?.response?.data.error);
        setShowErrorModal(`An Error Occured:  ${err?.response?.data.error}`);
      }
      setShowErrorModal(
        `If this error persists, please contact adminstration for further assistance`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Spin spinning={isLoading} />
      </div>
    );
  }

  return (
    <Layout
      style={{ background: 'transparent' }}
      className="flex justify-center items-center w-[300px]"
    >
      <CommonAlert
        title={'Reset Password Error'}
        message={showErrorModal}
        show={!!showErrorModal}
      />
      <Modal
        width={600}
        open={!!showSuccessModal}
        onCancel={() => {
          formRef.current?.resetForm();
          setShowSuccessModal('');
          navigate('/');
        }}
        title={'Reset Password'}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              formRef.current?.resetForm();
              setShowSuccessModal('');
              navigate('/');
            }}
          >
            <span className="indicator-label">{'Ok'}</span>
          </Button>,
        ]}
      >
        <div className="py-6 px-8 text-center">
          <div className="flex justify-center mb-4">
            <MdEmail className="text-blue-500 text-4xl" />
          </div>
          <Text className="text-gray-800 text-base font-medium">
            {showSuccessModal}
          </Text>
        </div>
      </Modal>
      <div className="mb-4 text-center">
        <Link to="/">
          <img
            alt="Logo"
            src={toAbsoluteUrl('media/logos/logo_dark.png')}
            className="h-[75px]"
          />
        </Link>
      </div>
      <div className="text-center mb-4" data-testid="loginTest">
        <Typography className="text-gray-900 font-semibold mb-3 text-lg">
          Reset your password
        </Typography>
        <Typography className="text-gray-500 font-medium mb-3 text-s">
          Use the form below to set your new password
        </Typography>
        <div className="spinner"></div>
      </div>
      <div className="flex flex-col items-center gap-4 mb-9">
        <Formik
          innerRef={(formikProps) => {
            if (formikProps) {
              formRef.current = formikProps;
            }
          }}
          initialValues={{
            new_password: '',
            confirm_password: '',
          }}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            await handleResetPwd(values);
          }}
          validationSchema={yupValidationPasswordWithoutOldPwd}
          validateOnBlur={true}
        >
          {({ values, setFieldValue, handleSubmit, errors }) => (
            <form onSubmit={handleSubmit} className="" id="setPwdForm">
              <div className={`flex flex-col justify-center gap-4`}>
                <div className="flex justify-center flex-col w-full gap-4 mb-3">
                  <div
                    data-testid="leaveTypeDropdown"
                    className="w-full flex gap-2 flex-col"
                  >
                    <LoomInput
                      name={'new_password'}
                      value={values.new_password || ''}
                      disabled={false}
                      label={'New Password'}
                      placeholder="New Password"
                      onChange={(e) => {
                        setFieldValue('new_password', e.target.value);
                      }}
                      type={
                        passwordVisibility.new_password ? 'text' : 'password'
                      }
                      suffix={
                        <PasswordToggle
                          onToggle={(visible) =>
                            handlePasswordToggle('new_password', visible)
                          }
                        />
                      }
                    />
                    <LoomInput
                      name={'confirm_password'}
                      value={values.confirm_password || ''}
                      disabled={false}
                      label={'Confirm New Password'}
                      placeholder="Confirm New Password"
                      onChange={(e) => {
                        setFieldValue('confirm_password', e.target.value);
                      }}
                      type={
                        passwordVisibility.confirm_password
                          ? 'text'
                          : 'password'
                      }
                      suffix={
                        <PasswordToggle
                          onToggle={(visible) =>
                            handlePasswordToggle('confirm_password', visible)
                          }
                        />
                      }
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <Text style={{ color: 'rgba(58, 53, 65, 0.6)' }}>
                      {'Password Requirements'}
                    </Text>
                    <Text style={{ color: 'rgba(58, 53, 65, 0.6)' }}>
                      <ul style={{ gap: 4 }}>
                        <li>
                          {'Minimum 8 characters long - the more, the better'}
                        </li>
                        <li>
                          {'At least one lowercase & one uppercase character'}
                        </li>
                        <li>{'At least one number & one special character'}</li>
                      </ul>
                    </Text>
                  </div>
                </div>
                <Button
                  type="primary"
                  size={'large'}
                  htmlType="submit"
                  form={'setPwdForm'}
                >
                  Reset Password
                </Button>
              </div>
            </form>
          )}
        </Formik>
        <Link
          to="/auth/"
          className="text-gray-800 flex gap-2 items-center mt-3"
        >
          <ArrowLeftOutlined />
          <span>Back to login</span>
        </Link>
      </div>
    </Layout>
  );
};

export default SetPwd;
