import { ForwardRefExoticComponent, RefAttributes, useMemo } from 'react';
import { useAuth } from '../features/auth';
import {
  PieChartOutlined,
  UnorderedListOutlined,
  PrinterOutlined,
  CalendarOutlined,
  WalletOutlined,
  ClockCircleOutlined,
  HistoryOutlined,
  FieldTimeOutlined,
  CarOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  ProjectOutlined,
  BookOutlined,
  ContactsOutlined,
  FormOutlined,
  CarryOutOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { useFeature } from '../features/feature/FeatureProvider';
export interface MenuItem {
  label: string; // The display label of the menu item
  key: string; // Unique key for the menu item, used for identification
  show: boolean; // Whether the item should be displayed
  icon?: ForwardRefExoticComponent<
    Omit<AntdIconProps, 'ref'> & RefAttributes<HTMLSpanElement>
  >;
  hasIds?: boolean;
  children?: MenuItem[]; // Optional nested menu items for submenus
}

export type MenuStructure = MenuItem[];
const useRouting = () => {
  const { allowIf, permissions } = useAuth();
  const { showFeature } = useFeature();

  const hasPermissions = useMemo(() => permissions.length > 0, [permissions]);

  const menuStructure = useMemo(
    () =>
      [
        {
          label: 'Dashboard',
          key: '/dashboard/',
          show:
            (showFeature('LEAVES') || showFeature('TIMESHEET')) &&
            (allowIf(['LeaveRequests_ReadWrite']) ||
              allowIf(['Timesheets_ReadWrite'])),
          icon: PieChartOutlined,
        },
        {
          label: 'Calendar',
          key: '/dashboard/leave/calendar',
          show:
            showFeature('LEAVES') &&
            (allowIf(['LeaveRequests_ReadWrite']) ||
              allowIf(['Timesheets_ReadWrite'])),
          icon: CalendarOutlined,
        },
        {
          label: 'Timesheets',
          key: 'sub1',
          show: showFeature('TIMESHEET'),
          icon: FieldTimeOutlined,
          children: [
            {
              label: 'Print Timesheet',
              key: '/dashboard/timesheet/print-timesheet',
              show: allowIf(['Timesheets_Approve']),
              icon: PrinterOutlined,
            },
            {
              label: 'Timesheet List',
              key: '/dashboard/timesheet/list',
              show: allowIf(['Timesheets_ReadWrite']),
              icon: UnorderedListOutlined,
              hasIds: true,
            },
            {
              label: 'Timesheet per Work Package',
              key: '/dashboard/timesheet/list/wp',
              show: allowIf(['Timesheets_ReadWrite']),
              icon: UnorderedListOutlined,
            },
          ],
        },
        {
          label: 'Projects',
          key: 'projects',
          show: showFeature('PROJECTS'),
          icon: SnippetsOutlined,
          children: [
            {
              label: 'Project Participations',
              key: '/dashboard/projects/report/list',
              show: allowIf(['Projects_Read']),
              icon: ProjectOutlined,
              hasIds: true,
            },
            {
              label: 'Project List',
              key: '/dashboard/projects/list',
              show: allowIf(['Projects_Read']),
              icon: ClockCircleOutlined,
              hasIds: true,
            },
          ],
        },
        {
          label: 'Project Reporting',
          key: 'project_reporting',
          show: showFeature('PROJECT_REPORTING'),
          icon: BookOutlined,
          children: [
            {
              label: 'Coordinator List',
              key: '/dashboard/projects/participants_reports',
              show: allowIf(['FinancialReporting_ViewAllBeneficiaries']),
              icon: FormOutlined,
              hasIds: true,
            },
            {
              label: 'Periodic Reports',
              key: '/dashboard/projects/periodic_reports',
              show: allowIf(['FinancialReporting_Read']),
              icon: FormOutlined,
              hasIds: true,
            },
          ],
        },
        {
          label: 'Project Events',
          key: 'projectsevents',
          show: showFeature('MEETING'),
          icon: ContactsOutlined,
          children: [
            {
              label: 'Meetings',
              key: '/dashboard/projects/meetings/list',
              show: allowIf(['Meetings_Read']),
              icon: HistoryOutlined,
              hasIds: true,
            },
            {
              label: 'Trips',
              key: '/dashboard/projects/meetings/trip/list',
              show: allowIf(['Trips_Read']),
              icon: CarOutlined,
              hasIds: true,
            },
            {
              label: 'Trip Approvals',
              key: '/dashboard/projects/meetings/trip/approval',
              show: allowIf(['Trips_Approve']),
              icon: FormOutlined,
              hasIds: true,
            },
          ],
        },
        {
          label: 'Human Resources',
          key: 'human_resources',
          show: showFeature('HUMAN_RESOURCES'),
          icon: UserOutlined,
          children: [
            {
              label: 'Leaves',
              key: '/dashboard/leave/*',
              show: allowIf(['LeaveRequests_ReadWrite']),
              icon: CarryOutOutlined,
              children: [
                {
                  label: 'Leave Approvals',
                  key: '/dashboard/leave/approval-list',
                  show: allowIf(['LeaveApprovals_ReadWrite']),
                  icon: HistoryOutlined,
                  hasIds: true,
                },
                {
                  label: 'My Leaves',
                  key: '/dashboard/leave/list',
                  show: allowIf(['LeaveRequests_ReadWrite']),
                  icon: WalletOutlined,
                  hasIds: true,
                },
                {
                  label: 'Leave History',
                  key: '/dashboard/leave/history',
                  show: allowIf(['LeaveHistory_ViewAll']),
                  icon: HistoryOutlined,
                  hasIds: true,
                },
              ],
            },

            {
              label: 'Teams',
              key: '/dashboard/human/teams',
              show: allowIf(['Teams_Read']),
              icon: UsergroupAddOutlined,
            },
          ],
        },
      ] as MenuStructure,
    [allowIf],
  );
  const filteredMenuStructure = useMemo(() => {
    const filterMenu = (menu: MenuStructure) => {
      const k = menu
        .filter((item) => item.show) // Only keep items where `show` is true
        .map((item) => {
          if (item.children) {
            const filteredChildren = filterMenu(
              item.children,
            ) as (MenuItem | null)[];
            // Include the parent only if it has visible children or no children at all
            return filteredChildren.length > 0
              ? { ...item, children: filteredChildren }
              : null;
          }
          return item; // Include items without children
        })
        .filter(Boolean); // Remove null entries
      return k;
    };
    return filterMenu(menuStructure);
  }, [menuStructure]);

  const getIndexRouteKey = useMemo(() => {
    if (!hasPermissions) return null;
    if (filteredMenuStructure.length === 0) return null;
    const firstMenuItem = filteredMenuStructure[0];
    if (!firstMenuItem) return null;

    if (firstMenuItem.children && firstMenuItem.children.length > 0) {
      return firstMenuItem.children[0]!.key;
    }

    return firstMenuItem.key;
  }, [filteredMenuStructure]);
  const isRouteExist = (key: string): boolean => {
    const searchMenu = (menu: MenuStructure): boolean => {
      for (const item of menu) {
        console.log('item', item);
        const splittedParams = key.split('?')
        if (splittedParams.length > 1) {
          item.key = splittedParams[0];
        }
        if (item.hasIds) return true;
        if (item.key === key) return true;
        if (item.children && searchMenu(item.children)) return true;
      }
      return false;
    };

    return searchMenu(filteredMenuStructure as MenuStructure);
  };
  const getParentKey = (key: string): string | null => {
    const findParentKey = (
      menu: MenuStructure | [],
      childKey: string,
    ): string | null => {
      const struct: { [key: string]: string } = {};
      for (const item of menu) {
        if (item.children) {
          item.children.map((child) => {
            struct[child.key] = item.key;
          });
        }
      }
      return struct[childKey] || null;
    };

    return findParentKey(filteredMenuStructure as MenuStructure, key);
  };

  return {
    menuStructure,
    filteredMenuStructure,
    getIndexRouteKey,
    hasPermissions,
    permissions,
    isRouteExist,
    getParentKey,
  };
};

export default useRouting;
