import { FC } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import Header from '../pages/layout/Header';
import { PREVIEW } from './Urls';
import UserProfile from '../pages/userProfile/UserProfile';

const ProfileRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path={PREVIEW}
          element={
            <>
              <Header title="User Information">
                <UserProfile />
              </Header>
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ProfileRoutes;
