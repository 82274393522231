import { Button } from 'antd';
import Prompt from '../../utils/useNavBlocker/useNavBlocker';
import { Formik, FormikProps } from 'formik';
import { AxiosError } from 'axios';
import { useNotifications } from '../../permissions/components/timesheet/Notification-service';
import { useState, useRef } from 'react';
import { LockOutlined } from '@ant-design/icons';
import FormCard from '../../utils/Forms/FormCard';
import ChangePasswordForm from './ChangePasswordForm';
import { AppColors } from '../../../shared/colors';
import { yupValidationPassword } from './passwordValidation';
import { changePwd } from '../../api-requests/Auth';

export type TChangePwd = {
  old_password: string;
  new_password: string;
  confirm_password: string;
};

const UserProfile = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef<FormikProps<TChangePwd> | null | undefined>();
  const { openNotification } = useNotifications();

  const submitStep = async (values: TChangePwd) => {
    console.log('values change pwd', values);
    try {
      setIsSubmitting(true);
      await changePwd({
        old_password: values.old_password,
        new_password: values.new_password,
      });
      openNotification('topRight', {
        title: `Change Password`,
        description: 'Your password changed successfully!',
        type: 'Success',
      });
      formRef.current?.resetForm();
      setIsSubmitting(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        setIsSubmitting(false);
        openNotification('topRight', {
          title: `Change Password`,
          description: err?.response?.data.error,
          type: 'Danger',
        });
      }
    }
  };

  return (
    <div
      className="px-0 py-2 md:px-8 md:py-10 2xl:py-10 2xl:px-44"
      style={{
        width: '100%',
        flex: 'flex',
        justifyContent: 'center',
      }}
    >
      <Prompt formRef={formRef} isSubmitting={isSubmitting} />
      <Formik
        innerRef={(formikProps) => {
          if (formikProps) {
            formRef.current = formikProps;
          }
        }}
        validationSchema={yupValidationPassword}
        initialValues={{
          old_password: '',
          new_password: '',
          confirm_password: '',
        }}
        enableReinitialize
        onSubmit={async (values) => {
          await submitStep(values);
        }}
        validateOnBlur={true}
      >
        {({ values, setFieldValue, handleSubmit, resetForm }) => {
          return (
            <form onSubmit={handleSubmit} id="changePwdForm">
              {/* <FormValidationNotification /> */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
                className="mt-4 h-full gap-4"
              >
                <FormCard
                  title="Change Password"
                  icon={<LockOutlined />}
                  collapsable={false}
                >
                  <ChangePasswordForm
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </FormCard>
              </div>
              <div className="flex items-center justify-end mt-2">
                <Button
                  data-testid="changePwd"
                  size={'large'}
                  style={{
                    background: AppColors.primary,
                    color: 'white',
                  }}
                  // onClick={() => {
                  //   console.log('Submit');
                  // }}
                  htmlType="submit"
                  form={'changePwdForm'}
                >
                  <span className="indicator-label">Change Password</span>
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UserProfile;
