import axios from 'axios';
import envConfig from '../../enviroment/enviroment';

const API_URL = envConfig.getEnvKey('APP_DEV_URL');

export const LOGIN = `${API_URL}/oauth/signin`;
export const CHANGE_PWD = `${API_URL}/oauth/password/change`;
export const REQUEST_RESET_PWD = `${API_URL}/oauth/password/reset/request`;
export const RESET_PWD = `${API_URL}/oauth/password/reset/confirm`;

export const login = async (values: { username: string; password: string }) => {
  try {
    const response = await axios.post<{ Token: string }>(LOGIN, values);
    return response.data;
  } catch (error) {
    console.error('Error in login:', error);
    throw error;
  }
};

export const changePwd = async (values: {
  old_password: string;
  new_password: string;
}) => {
  try {
    const response = await axios.post<{ Token: string }>(CHANGE_PWD, values);
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};

export const requestResetPwd = async (values: string) => {
  try {
    const response = await axios.post<{ Token: string }>(
      REQUEST_RESET_PWD,
      values,
    );
    return response.data;
  } catch (error) {
    console.error('Error requesting reset password:', error);
    throw error;
  }
};

export const resetPwd = async (values: {
  email: string;
  rt: string;
  new_password: string;
}) => {
  try {
    const response = await axios.post<{ Token: string }>(RESET_PWD, values);
    return response.data;
  } catch (error) {
    console.error('Error reseting password:', error);
    throw error;
  }
};
