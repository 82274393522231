import { Layout, Typography, Button, Spin, Modal } from 'antd';
import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../helpers';
import { Formik, FormikProps } from 'formik';
import LoomInput from '../../../../components/StyledComponents/LoomInput';
import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import { requestResetPwd } from '../../../../api-requests/Auth';
import { yupValidationEmail } from './emailValidation';
import CommonAlert from '../../../../components/alert/CommonAlert';
import { MdEmail } from 'react-icons/md';

const { Text } = Typography;

const ResetPwd = () => {
  const formRef = useRef<FormikProps<{ email: string }>>();
  const [showErrorModal, setShowErrorModal] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleRequestResetPwd = async (values: { email: string }) => {
    setIsLoading(true);
    try {
      const response = await requestResetPwd(values.email);
      console.log('success?', response);
      setShowSuccessModal(
        'Instructions on how to reset your password will be sent to your email if it matches an account in our system.',
      );
    } catch (err) {
      //we cannot find and email associated with an account
      if (err instanceof AxiosError) {
        console.log('ERROR', err?.response?.data.error);
        setShowErrorModal(`An Error Occured:  ${err?.response?.data.error}`);
      }
      setShowErrorModal(
        `If this error persists, please contact adminstration for further assistance`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Spin spinning={isLoading} />
      </div>
    );
  }

  return (
    <Layout
      style={{ background: 'transparent' }}
      className="flex justify-center items-center w-[300px]"
    >
      <CommonAlert
        title={'Reset Request Password Error'}
        message={showErrorModal}
        show={!!showErrorModal}
      />
      <Modal
        width={600}
        open={!!showSuccessModal}
        onCancel={() => {
          formRef.current?.resetForm();
          setShowSuccessModal('');
          navigate('/');
        }}
        title={'Reset Request Password'}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              formRef.current?.resetForm();
              setShowSuccessModal('');
              navigate('/');
            }}
          >
            <span className="indicator-label">{'Ok'}</span>
          </Button>,
        ]}
      >
        <div className="py-6 px-8 text-center">
          <div className="flex justify-center mb-4">
            <MdEmail className="text-blue-500 text-4xl" />
          </div>
          <Text className="text-gray-800 text-base font-medium">
            {showSuccessModal}
          </Text>
        </div>
      </Modal>
      <div className="mb-4 text-center">
        <Link to="/">
          <img
            alt="Logo"
            src={toAbsoluteUrl('media/logos/logo_dark.png')}
            className="h-[75px]"
          />
        </Link>
      </div>
      <div className="text-center mb-4" data-testid="loginTest">
        <Typography className="text-gray-900 font-semibold mb-3 text-lg">
          Forgot your password
        </Typography>
        <Typography className="text-gray-500 font-medium mb-3 text-s">
          Please enter your email address to get instructions on how to reset
          your password
        </Typography>
        <div className="spinner"></div>
      </div>
      <div className="flex flex-col items-center gap-4 mb-9">
        <Formik
          innerRef={(formikProps) => {
            if (formikProps) {
              formRef.current = formikProps;
            }
          }}
          initialValues={{ email: '' }}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            await handleRequestResetPwd(values);
          }}
          validationSchema={yupValidationEmail}
          validateOnBlur={true}
        >
          {({ values, setFieldValue, handleSubmit, errors }) => (
            <form onSubmit={handleSubmit} className="" id="emailForm">
              <div className={`flex flex-col justify-center gap-4`}>
                <div className="flex justify-center flex-col w-full gap-4 mb-3">
                  <div
                    data-testid="leaveTypeDropdown"
                    className="w-full flex gap-2 flex-col"
                  >
                    <LoomInput
                      name="email"
                      placeholder="Email"
                      prefix={<UserOutlined />}
                      value={values.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      label={'Email'}
                      disabled={false}
                    />
                  </div>
                </div>
                <Button
                  type="primary"
                  size={'large'}
                  htmlType="submit"
                  form={'emailForm'}
                >
                  Reset Password
                </Button>
              </div>
            </form>
          )}
        </Formik>
        <Link
          to="/auth/"
          className="text-gray-800 flex gap-2 items-center mt-3"
        >
          <ArrowLeftOutlined />
          <span>Back to login</span>
        </Link>
      </div>
    </Layout>
  );
};

export default ResetPwd;
