import React, { forwardRef } from 'react';
import { Button } from 'antd';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';

type Props = {
  click: () => void;
  icon?: 'add' | 'export';
  text?: string;
  testId?: string;
  style?: React.CSSProperties;
  className?: string;
};

const LoomButton = forwardRef<HTMLButtonElement, Props>(
  ({ click, icon, testId, style, className, ...rest }, ref) => {
    const iconType = () => {
      switch (icon) {
        case 'add':
          return <PlusOutlined />;
        case 'export':
          return <DownloadOutlined />;
        default:
          return null;
      }
    };

    return (
      <Button
        ref={ref}
        data-testid={testId}
        size="small"
        icon={iconType()}
        style={{ border: 'none', ...style }}
        className={`bg-primary text-white ${className}`}
        onClick={click}
        {...rest} // Spread all remaining props to the button (e.g., onMouseEnter, onMouseLeave)
      />
    );
  }
);

LoomButton.displayName = 'LoomButton';
export default LoomButton;
