import { createContext } from 'react';
import { BalanceData } from '../../../@types/Balance';

export interface IBalanceContext {
    loading: boolean;
    getBalance: (year: number, id?: number) => void;
    balance: BalanceData[];
}

const BalanceContext = createContext<IBalanceContext | undefined>(undefined);

BalanceContext.displayName = 'Balance';

export default BalanceContext;
