//Timesheets URLS
export const ADD_TIMESHEET = '/create';
export const LIST_TIMESHEET = '/list';
export const PRINT_TIMESHEET = '/print-timesheet';
export const PREVIEW_TIMESHEET = '/:id';
export const LIST_TIMESHEET_PER_WP = '/list/wp';

//Auth URLS
export const LOGIN = '/login';
export const RESET_PWD = '/reset-password';
export const SET_PWD = '/password/reset/confirm';

//BASIC ROUTES
export const GET_LIST = '/list';
export const CREATE = '/create';
export const GET_APPROVAL_LIST = '/approval';
export const PREVIEW = '/:id';

//LEAVE ROUTES

export const LEAVE_HISTORY_TABLE = '/history';
export const LEAVE_APPROVAL_TABLE = '/approval-list';
export const LEEAVE_CALENDAR = '/calendar';
