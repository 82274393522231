import * as yup from 'yup';

const UpperLowerCaseRegex = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
const NumberSymbolRegex = /^(?=.*\d)(?=.*[^\w\s]).+$/;

export const yupValidationPassword = yup.object().shape({
  old_password: yup.string().required('Password is Required'),
  new_password: yup
    .string()
    .required('Password is Required')
    .min(8, 'Password should be minimum 8 character')
    .max(50, 'Too long')
    .matches(
      UpperLowerCaseRegex,
      'At least one lowercase & one uppercase character',
    )
    .matches(NumberSymbolRegex, 'At least one number & one special character'),
  confirm_password: yup
    .string()
    .required('Confirm Password is Required')
    .oneOf([yup.ref('new_password')], 'Password does not matched'),
});

export const yupValidationPasswordWithoutOldPwd = yupValidationPassword.omit([
  'old_password',
]);
