import {
    FC,
    ReactNode,
    ReactPortal,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { fetchBalance } from '../../../api-requests/Balance';
import { BalanceData } from '../../../@types/Balance';
import { useAuth } from '../../auth';
import BalanceContext from './BalanceContext';
import { useNotifications } from '../../../permissions/components/timesheet/Notification-service';

type TUserProviderProps = {
    children: ReactNode | ReactPortal;
};
export type FilterData = {
    id: number;
    label: string;
    value: boolean;
};

//we check if the leave balances are valid. By checking the policy property
//this is because of a bug in the admin panel that allows the user to add a leave policy to the user without a type of leave
function ValidateAndModifyLeaveBalances(balances: BalanceData[]) {
    const modifiedBalances = balances.filter((balance) => balance.Policy);
    return {
        valid: modifiedBalances.length === balances.length,
        balances: modifiedBalances
    }
}

export const BalanceProvider: FC<TUserProviderProps> = ({ children }) => {
    const { id } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const { openNotification } = useNotifications();
    const [balance, setBalance] = useState<BalanceData[]>([]);

    const getBalance = async (year: number, employeeId?: number) => {
        setLoading(true);
        try {
            const res = await fetchBalance({ personId: employeeId ? employeeId : id, year: year });
            const { valid, balances } = ValidateAndModifyLeaveBalances(res.Results);

            if (!valid) openNotification('topRight', {
                title: "Leave Balances Validation Warning",
                description: 'An issue was detected during the leave balances validation.Please contact the administrator to fix this issue.',
                type: 'Warning',
            });

            setBalance(balances);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching balance:', error);
            throw error;
        }
    };
    useEffect(() => {
        if (id) {
            getBalance(new Date().getFullYear())
        }
    }, [id]);
    const contextValue = useMemo(
        () => ({
            loading,
            balance,
            getBalance,
        }),
        [
            loading,
            balance,
        ],
    );
    return (
        <BalanceContext.Provider value={contextValue}>
            {children}
        </BalanceContext.Provider>
    );
};
