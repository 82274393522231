import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useTrackLocation = () => {
  const location = useLocation();
  console.log('location', location)
  const previousPath = useRef<string | null>(null);
  useEffect(() => {
    console.log('trigger')
    const currentPath = location.pathname;
    if (currentPath.includes('auth') || currentPath === '/') return;

    localStorage.setItem('redirectAfterLogin', currentPath + location.search);

    previousPath.current = currentPath + location.search;
  }, [location]);

  return location;
};

export default useTrackLocation;
