import { Alert } from 'antd';
import { FC } from 'react';

const CommonAlert: FC<{
  show: boolean;
  message: string;
  title: string;
}> = ({ show, message, title }) => {
  return (
    show && (
      <Alert
        message={title}
        description={message}
        type="error"
        className="mb-4"
      />
    )
  );
};

export default CommonAlert;
