import { Avatar, Button, Dropdown, MenuProps, Space, Typography } from 'antd';
import React from 'react';
import { useAuth } from '../../features/auth';
import { theme, Divider } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { AppVersion } from '../../components/App-version';
import { useNavigate } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  path?: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    path,
  } as MenuItem;
}

const UserDropdown = () => {
  const { logout, currentName, id, currentFullName } = useAuth();
  const navigate = useNavigate();

  const {
    token: { colorBgElevated, borderRadiusLG, boxShadowSecondary },
  } = theme.useToken();
  const contentStyle: React.CSSProperties = {
    backgroundColor: colorBgElevated,
    borderRadius: borderRadiusLG,
    boxShadow: boxShadowSecondary,
  };
  const items: MenuProps['items'] = [
    getItem(
      <div className="flex gap-2 items-center justify-start py-1">
        <LockOutlined />
        <Typography onClick={() => navigate('/loom/' + id)}>
          {'Change Password'}
        </Typography>
      </div>,
      '1',
      '',
    ),
  ];
  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };
  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          <div className="px-4 py-3 text-left">
            <Typography className="cursor-default font-semibold">
              {currentFullName}
            </Typography>
            <div className=" text-left  ">
              <Typography className="cursor-default font-semibold text-gray-500">
                {currentName}
              </Typography>
              {/* <Typography className="cursor-default font-light text-gray-500">
                {currentRole}
              </Typography> */}
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
          {React.cloneElement(menu as React.ReactElement, {
            style: menuStyle,
          })}
          <Divider style={{ margin: 0 }} />
          <div className="mt-2 text-center">
            <AppVersion theme={'dark'} />
          </div>
          <Space
            style={{
              padding: 8,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              type="default"
              onClick={() => {
                logout();
              }}
            >
              Sign out
            </Button>
          </Space>
        </div>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <Avatar icon={<UserOutlined />} data-testid="userAvatar" />
        </Space>
      </a>
    </Dropdown>
  );
};

export default UserDropdown;
