import { Typography } from 'antd';
import LoomInput from '../../components/StyledComponents/LoomInput';
import { useState } from 'react';
import PasswordToggle from '../../components/ui/PasswordToggle';
import { TChangePwd } from './UserProfile';

const { Text } = Typography;

type Props = {
  values: TChangePwd;
  setFieldValue: (key: string, value: unknown) => void;
};

const ChangePasswordForm = ({ values, setFieldValue }: Props) => {
  const [passwordVisibility, setPasswordVisibility] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const handlePasswordToggle = (field: string, visible: boolean) => {
    setPasswordVisibility((prev) => ({ ...prev, [field]: visible }));
  };

  return (
    <div className="flex flex-col justify-center items-center gap-2 py-14">
      <div className="flex flex-col  gap-8 w-1/2">
        <LoomInput
          name={'old_password'}
          value={values.old_password || ''}
          disabled={false}
          label={'Current Password'}
          placeholder="Current Password"
          onChange={(e) => {
            console.log('HERE', e.target.value);
            setFieldValue('old_password', e.target.value);
          }}
          type={passwordVisibility.old_password ? 'text' : 'password'}
          suffix={
            <PasswordToggle
              onToggle={(visible) =>
                handlePasswordToggle('old_password', visible)
              }
            />
          }
        />
        <LoomInput
          name={'new_password'}
          value={values.new_password || ''}
          disabled={false}
          label={'New Password'}
          placeholder="New Password"
          onChange={(e) => {
            setFieldValue('new_password', e.target.value);
          }}
          type={passwordVisibility.new_password ? 'text' : 'password'}
          suffix={
            <PasswordToggle
              onToggle={(visible) =>
                handlePasswordToggle('new_password', visible)
              }
            />
          }
        />
        <LoomInput
          name={'confirm_password'}
          value={values.confirm_password || ''}
          disabled={false}
          label={'Confirm New Password'}
          placeholder="Confirm New Password"
          onChange={(e) => {
            setFieldValue('confirm_password', e.target.value);
          }}
          type={passwordVisibility.confirm_password ? 'text' : 'password'}
          suffix={
            <PasswordToggle
              onToggle={(visible) =>
                handlePasswordToggle('confirm_password', visible)
              }
            />
          }
        />
      </div>
      <div className="flex flex-col mt-4 w-1/2">
        <Text style={{ color: 'rgba(58, 53, 65, 0.6)' }}>
          {'Password Requirements'}
        </Text>
        <Text style={{ color: 'rgba(58, 53, 65, 0.6)' }}>
          <ul style={{ gap: 4 }}>
            <li>{'Minimum 8 characters long - the more, the better'}</li>
            <li>{'At least one lowercase & one uppercase character'}</li>
            <li>{'At least one number & one special character'}</li>
          </ul>
        </Text>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
