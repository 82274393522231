import { Outlet } from 'react-router-dom';
import { MasterInit } from './pages/layout/MasterInit';
import { AuthInit } from './features/auth';
import { NotificationProvider } from './permissions/components/timesheet/Notification-service';
import { TimesheetProvider } from './features/timesheet/provider/timesheetProvider';
import { Suspense } from 'react';
import { FeatureProvider } from './features/feature/FeatureProvider';
import { TeamProvider } from './features/humanResources/teams/provider/TeamProvider';
import { MeetingProvider } from './features/meeting/provider/meetingProvider';
import { TripProvider } from './features/trip/provider/tripProvider';
import {
  LeaveCategory,
  LeaveProvider,
} from './features/leave/provider/GenericLeaveProvider';
import { Spin } from 'antd';
import { ProjectProvider } from './features/project/projects/provider/ProjectProvider';
import { HolidaysProvider } from './features/leave/components/Calendar/provider/HolidaysProvider';

const App = () => {
  return (
    <Suspense fallback={<Spin />}>
      <FeatureProvider>
        <NotificationProvider>
          <AuthInit>
            <ProjectProvider>
              <TeamProvider>
                <TimesheetProvider>
                  <LeaveProvider category={LeaveCategory.CalendarLeaves}>
                    <MeetingProvider>
                      {/* <HolidaysProvider> */}
                      <TripProvider>
                        <Outlet />
                        <MasterInit />
                      </TripProvider>
                      {/* </HolidaysProvider> */}
                    </MeetingProvider>
                  </LeaveProvider>
                </TimesheetProvider>
              </TeamProvider>
            </ProjectProvider>
          </AuthInit>
        </NotificationProvider>
      </FeatureProvider>
    </Suspense>
  );
};

export { App };
