import { useState } from 'react';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const PasswordToggle = ({
  onToggle,
}: {
  onToggle: (visible: boolean) => void;
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const handleToggle = () => {
    const newVisibility = !isPasswordVisible;
    setPasswordVisible(newVisibility);
    onToggle(newVisibility);
  };

  return (
    <Button
      size="small"
      type="text"
      icon={isPasswordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      onClick={handleToggle}
      style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
    />
  );
};

export default PasswordToggle;
