import { Button } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Location, unstable_useBlocker as useBlocker } from "react-router-dom";
import WarningModal from "../../components/modal/WarningModal";
import { LeaveColors } from "../../../shared/colors";
import { FormikProps } from "formik";

interface Props<T> {
    formRef: React.RefObject<FormikProps<T> | null | undefined>; // RefObject with generic type T
    isSubmitting: boolean;
}
export declare type BlockerFunction = (args: {
    currentLocation: Location;
    nextLocation: Location;
    historyAction: Action;
}) => boolean;
export declare enum Action {

    Pop = "POP",
    Push = "PUSH",
    Replace = "REPLACE"
}
const Prompt = <T,>({ formRef, isSubmitting }: Props<T>) => {
    const [show, setShow] = useState(false);

    const shouldBlock = useCallback(
        ({ currentLocation, nextLocation }: { currentLocation: Location; nextLocation: Location }) =>
            !!formRef.current?.dirty && currentLocation.pathname !== nextLocation.pathname && !isSubmitting,
        [formRef, isSubmitting]
    );

    const blocker = useBlocker(shouldBlock as BlockerFunction);

    useEffect(() => {
        if (blocker.state === "blocked" && !formRef.current?.dirty) {
            blocker.reset();
        } else if (blocker.state === 'blocked' && formRef.current?.dirty && !isSubmitting) {
            setShow(true);
        }
    }, [blocker, formRef, isSubmitting]);

    return (
        <WarningModal
            title="Unsaved Changes"
            isOpen={show}
            setIsOpen={setShow}
            footerButtons={[
                <Button key={0} onClick={() => { if (blocker.proceed && formRef.current) { formRef.current.resetForm(); blocker.proceed(); } }}>Leave</Button>,
                <Button
                    key={1}
                    onClick={() => { setShow(false); blocker.reset?.(); }}
                    style={{ background: LeaveColors[0].value, color: 'white' }}
                >
                    Return to form
                </Button>,
            ]}
            mainText="Are you sure you want to leave the page without submitting the form? Your changes will not be saved."
        />
    );
}

export default Prompt;
